import { CALL_API } from 'redux-api-middleware'
import { isLocal } from '../utils/helpers'
import Messages from '../constants/messages'
import {
  CLIENT_CONFIGS_FAILURE,
  LOGIN_CONFIGS_URL_FAILURE
} from './clientConfigurations'
import {
  FORGOT_PASSWORD_EMAIL_SEND_RECEIVE,
  FORGOT_PASSWORD_EMAIL_SEND_FAILURE
} from './forgotPassword'
import {
  ACCOUNT_LOCKED_OUT,
  AUTHENTICATION_FAILED,
  EXTRACT_AUTH_CONFIGS_FAILED,
  INIT_AUTH_CLIENT_FAILED,
  PASSWORD_HAS_EXPIRED,
  SESSION_CHECKED,
  LOCATION_CHANGE
} from './login'
import { NO_ONBOARD_DATA } from './onboard'
export const INC_CUSTOM_METRIC_REQUEST = 'notifications/INC_CUSTOM_METRIC_REQUEST'
export const INC_CUSTOM_METRIC_RECEIVE = 'notifications/INC_CUSTOM_METRIC_RECEIVE'
export const INC_CUSTOM_METRIC_FAILURE = 'notifications/INC_CUSTOM_METRIC_FAILURE'
export const SET_IN_PROGRESS = 'notifications/SET_IN_PROGRESS'
export const SET_ACTIVE_LOGIN_SKIN = 'notifications/SET_ACTIVE_LOGIN_SKIN'
export const SHOW_PASSWORD_WARNING = 'notifications/SHOW_PASSWORD_WARNING'

const initialState = {
  checkSessionInProgress: true,
  signInInProgress: false,
  showNotification: false,
  showPasswordWarning: false,
  message: '',
  messageType: ''
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_LOCKED_OUT:
      return {
        ...state,
        showNotification: true,
        signInInProgress: false,
        message: Messages['pageMessages']['ACCOUNT_LOCKED_OUT'],
        messageType: 'error'
      }
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        signInInProgress: false,
        showNotification: true,
        message: Messages['activeLoginSkin'][action.activeLoginSkinName]['AUTHENTICATION_FAILED'],
        messageType: 'error'
      }
    case CLIENT_CONFIGS_FAILURE:
      return state
    case EXTRACT_AUTH_CONFIGS_FAILED:
      return {
        ...state,
        showNotification: true,
        message: Messages['authClientErrors']['EXTRACT_AUTH_CONFIGS_FAILED'],
        messageType: 'error'
      }
    case INIT_AUTH_CLIENT_FAILED:
      return {
        ...state,
        showNotification: true,
        message: Messages['authClientErrors']['INIT_AUTH_CLIENT_FAILED'],
        messageType: 'error'
      }
    case LOCATION_CHANGE:
      return {
        ...state,
        showNotification: false
      }
    case LOGIN_CONFIGS_URL_FAILURE:
      return {
        ...state,
        showNotification: true,
        message: Messages['clientLoginConfigErrors']['LOGIN_CONFIGS_URL_FAILURE'],
        messageType: 'error'
      }
    case NO_ONBOARD_DATA:
      return {
        ...state,
        showNotification: true,
        message: Messages['authClientErrors']['EXTRACT_ONBOARD_DATA_FAILED'],
        messageType: 'error'
      }
    case PASSWORD_HAS_EXPIRED:
      return {
        ...state,
        showNotification: true,
        signInInProgress: false,
        message: Messages['activeLoginSkin'][action.activeLoginSkinName]['PASSWORD_HAS_EXPIRED'],
        messageType: 'error'
      }
    case FORGOT_PASSWORD_EMAIL_SEND_RECEIVE:
      return {
        ...state,
        showNotification: true,
        message: Messages['pageMessages']['FORGOT_PASSWORD_EMAIL_SUCCESS'],
        messageType: 'success'
      }
    case FORGOT_PASSWORD_EMAIL_SEND_FAILURE:
      return {
        ...state,
        showNotification: true,
        message: Messages['pageMessages']['FORGOT_PASSWORD_EMAIL_FAILURE'],
        messageType: 'error'
      }
    case SESSION_CHECKED:
      return {
        ...state,
        checkSessionInProgress: false
      }
    case SET_IN_PROGRESS:
      return {
        ...state,
        signInInProgress: action.inProgress
      }
    case SHOW_PASSWORD_WARNING:
      return {
        ...state,
        showPasswordWarning: action.shouldShow
      }
    default:
      return state
  }
}

export function incrementCustomMetric(metric, tags) {
  const customMetricsEndpoint = `/custom-metrics/increment/${metric}`
  const tagsQuery = tags && tags.length > 0 ? `?tags=${tags.join(',')}` : null
  return {
    [CALL_API]: {
      endpoint: isLocal() ? `http://localhost:8080${customMetricsEndpoint}${tagsQuery || ''}` : `${customMetricsEndpoint}${tagsQuery || ''}`,
      method: 'GET',
      types: [INC_CUSTOM_METRIC_REQUEST, INC_CUSTOM_METRIC_RECEIVE, INC_CUSTOM_METRIC_FAILURE]
    }
  }
}

export function setSignInInProgress(inProgress) {
  return { type: SET_IN_PROGRESS, inProgress }
}

export function setNoOnboardData() {
  return { type: NO_ONBOARD_DATA }
}

export function showPasswordWarning(shouldShow) {
  return { type: SHOW_PASSWORD_WARNING, shouldShow}
}

export default reducer
