import React, { Component } from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { flattenObject } from '../../utils/helpers'
import InputField from './inputField'
import PropTypes from 'prop-types'
import SignInButton from './signInButton'
import { required } from '../../utils/validationRules'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  authError: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  passwordFieldLabel: PropTypes.string.isRequired,
  passwordFieldType: PropTypes.string.isRequired,
  signInInProgress: PropTypes.bool.isRequired,
  signInButtonLabel: PropTypes.string.isRequired,
  userNameFieldLabel: PropTypes.string.isRequired,
  userNameFieldType: PropTypes.string.isRequired
}

const styles = theme => ({

})

export class LoginForm extends Component {

  render() {
    const {
      authError,
      disableForm,
      handleFormSubmit,
      handleSubmit,
      passwordFieldLabel,
      passwordFieldType,
      signInInProgress,
      signInButtonLabel,
      userNameFieldLabel,
      userNameFieldType } = this.props

    return (
      <form className="login-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <InputField
          authError={authError}
          autoFocus={true}
          disableForm={disableForm}
          name="username"
          placeholder={userNameFieldLabel}
          type={userNameFieldType}
          validate={[required]} />
        <InputField
          authError={authError}
          autoFocus={false}
          disableForm={disableForm}
          name="password"
          placeholder={passwordFieldLabel}
          type={passwordFieldType}
          validate={[required]} />
        <SignInButton
          disableForm={disableForm}
          signInButtonLabel={signInButtonLabel}
          signInInProgress={signInInProgress} />
      </form>
    )
  }
}

/**
 * Props definition
 */
LoginForm.propTypes = PROP_TYPES

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'login',
    onSubmitFail: (errors) => {
      // # Focus on first error

      // We do this using a DOM selector of all the fields that have error'ed and use [`querySelector`](https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector) which
      // Returns the first Element within the document that matches the specified selector, or group of selectors.
      const errorEl = document.querySelector(
        Object.keys(flattenObject(errors)).map(fieldName => `[name="${fieldName}"]`).join(',')
      )
      if (errorEl && errorEl.focus) {
        errorEl.focus()
      }
    }
  })
)(LoginForm)
