import React, { Component } from 'react'
import { CircularProgress } from 'material-ui/Progress'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

const styles = theme => ({
  pageMessage: theme.pageMessage,
  progress: theme.progress,
  message: theme.message
})

export class SessionCheck extends Component {

  render() {
    const { classes, message } = this.props

    return (
      <div className={classes.pageMessage} data-test-id="session-check">
        <CircularProgress className={classes.progress} size={50} />
        <span className={classes.message}>{message}</span>
      </div>
    )
  }
}

/**
 * Props definition
 */
SessionCheck.propTypes = PROP_TYPES

export default withStyles(styles)(SessionCheck)
