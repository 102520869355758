import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'
import TextField from 'material-ui/TextField'
import Messages from '../../constants/messages'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formHelperText: {
    backgroundColor: '#FFFFFF',
    marginTop: 0,
    paddingTop: '8px',
    paddingLeft: '8px'
  },
  inputLabelPadding: {
    paddingLeft: '12px'
  },
  inputPading: {
    'paddingLeft': '10px'
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 300,
  }
})

class ValidationTextField extends React.Component {
  state = {
    focused: false,
    name: '',
    touched: false
  }

  handleChange = () => event => {
    const { oktaEmail, setIsValidated } = this.props
    this.setState({
      name: event.target.value,
      touched: true
    })
    event.target.value.toLowerCase().trim() === oktaEmail.toLowerCase() ? setIsValidated(true) : setIsValidated(false)
  }

  componentWillUnmount() {
    this.props.setIsValidated(false)
  }
  render() {
    const { classes, fieldError, isValidated } = this.props

    return (
      <div className={classes.container}>
        <TextField
          autoFocus
          data-test-id='validation-text-field'
          error={fieldError && !isValidated && !this.state.focused ? true : false}
          id='name'
          inputProps={{
            className: classes.inputPading,
            'data-test-id':'validation-input',
            onBlur: ()=> {
              this.setState({focused: false})
            },
            onFocus: () => {
              this.setState({focused: true, touched: true})
            }
          }}
          InputLabelProps={{
            shrink: true,
            className: classes.inputLabelPadding
          }}
          FormHelperTextProps={{
            className: classes.formHelperText
          }}
          label={Messages['pageMessages']['USERNAME']}
          className={classes.textField}
          value={this.state.name}
          helperText={Messages['pageMessages']['TEXT_FIELD_HELPER']}
          onChange={this.handleChange()}
          margin="normal"
        />
      </div>
    )
  }
}

ValidationTextField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ValidationTextField)
