import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import TextField from 'material-ui/TextField'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  authError: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validate: PropTypes.array
}

const errorIconBase64 = 'iVBORw0KGgoAAAANSUhEUgAAAB0AAAAYCAYAAAAGXva8AAAAAXNSR0IArs4c6QAAAqhJREFUSA21Vc9rE1EQnt1sraRVi8FQFSxFg4KF7uYXFosKQg6evKRQ0EsOCh6ECkKjUiuK4A/Qtn+DoM3B9iSe1YNN4qaHKIkeRaQ9iFhSkmzeOLv4wmazu8lG+i7z5ptv5svkzb4H0MP6pCjn12RZXVOUsz2kg+A1KReJ+LHBviDiERCErwOhY2MnM5malzqiF7LOZYylDUHdQQxtl7/N6Fsvy1On6/H4aLVa/0xqu7kIFdjyicLxsKr+4Fgn66nTWq3+zCyoF0eAQQ3xcSchc7zrTnPjkQSDxlueLPr9wCoV7tLxSpOxQu5DE3DZdNUpDU8fAlvgdQ6mUhB+/w6C09Mcopa1JZyf76peVySanusIeIIr7Js8DYIowhBZvuhvVvKrq1e472Y7ihZjsWGGMOdWhMeI96A4MbGf+062o2ilrj2iT2OvU4EWHDGwvV2934LZOK6i+XD4FAletslzhBjgVbqxxh0JFHAU1YeiwXCJzqrrCTeEEH0awmJPovmVlRR1GXVLdowhnskqimm0W5m2naryuSEE4WEr1ZtHV+WT9URiwC7LVrSOv+9R0gG7hK4xhMP1jc3bdvw20Ww0OkaneM2OzDHUNGPL/lmOWy398Bt0sRy14m2ioGmLdJaSlWj2N16+gkq5DJvLGTPctqch7Gda47k10DKZOVlO0ge+bCX9ry+IwoWYqr7hdZqdGo8zCE95wM32BYMQnJoCKRBwozVjiLBQTCZ3caApyjQ223ycedTBjt6dg5FbaRhJzzowLLDlsTdE9ceZaDctVEe3UirRsSNUSmVHjjVAD8YduqkO6bhxpllZfk01LlqJbr5vzyA0/my5UdpjAryIFwqXBFKPagyz7YydQXw+MST1S9L3Rq3+kZ7+4Z2RMVVF/OmXpF9/AQIG8ByPwZkdAAAAAElFTkSuQmCC'

const styles = theme => ({
  disabled: theme.disabled,
  errorIcon: {
    position: 'absolute',
    left: -35,
    top: 6,
    background: {
      image:`url("data:image/png;base64,${errorIconBase64}")`,
      repeat: 'no-repeat',
      position: ['left center']
    },
    backgroundSize: '29px 25px',
    width: 29,
    height: 25
  },
  formField: {
    display: 'block',
    margin: '53px auto',
    width: 252
  },
  textFieldInput: {
    paddingBottom: 3,
    '&.authError': {
      color: '#ca2f30'
    },
    '&.hiddenNumeric': {
      '-webkit-text-security': 'disc'
    },
    '&::placeholder': {
      color: '#676767',
      opacity: 1
    },
    '&:disabled:before': {
      background: '#676767 !important'
    }
  },
  textFieldRoot: {
    fontSize: 24
  },
  helperText: {
    fontSize: 18,
    fontWeight: 'bold',
    height: 17,
    '&.error': {
      color: '#d0021b',
      background: {
        image:`url("data:image/png;base64,${errorIconBase64}")`,
        repeat: 'no-repeat',
        position: ['left center']
      },
      backgroundSize: '20px 17px',
      paddingLeft: 25
    }
  },
  underline: {
    '&:hover:not(.MuiInput-disabled-30):before': {
      height: 1,
      backgroundColor: '#676767'
    },
    '&.authError&:before': {
      backgroundColor: '#ca2f30'
    },
    '&:before': {
      backgroundColor: '#676767'
    },
    '&:after': {
      backgroundColor: '#676767',
      transform: 'scaleX(0) !important'
    },
    '&[class*="jss"]:before': {
      background: '#676767 !important'
    }
  }
})

let cx = classNames.bind(styles)

export class InputField extends Component {

  renderField(props) {
    const {
      authError,
      autoFocus,
      classes,
      disableForm,
      input,
      placeholder,
      type,
      meta: {touched, error}
    } = props

    return (
      <TextField
        autoFocus={autoFocus}
        className={classes.formField}
        data-test-id={input.name}
        disabled={disableForm}
        fullWidth={true}
        helperText={touched && error ? error : ""}
        {...input}
        InputProps={{
          classes: {
            root: authError ? `authError ${classes.textFieldRoot}` : classes.textFieldRoot,
            input: cx(
              classes.textFieldInput,
              {'authError': authError},
              {'error': touched && error},
              {'hiddenNumeric': input.name === 'password' && type === 'tel'}
            ),
            underline: classes.underline
          },
          startAdornment: authError ? <div className={classes.errorIcon}></div> : null
        }}
        FormHelperTextProps={{
          className: cx(classes.helperText, {'error': touched && error}),
          shrink: 'true'
        }}
        placeholder={placeholder}
        onBlur={() => {}}
        type={type}
      />
    )
  }

  render() {
    const {
      authError,
      autoFocus,
      classes,
      disableForm,
      name,
      placeholder,
      type,
      validate
    } = this.props

    return (
      <Field
        authError={authError}
        autoFocus={autoFocus}
        classes={classes}
        disableForm={disableForm}
        component={this.renderField}
        name={name}
        placeholder={placeholder}
        type={type}
        validate={validate} />
    )
  }
}

/**
 * Props definition
 */
InputField.propTypes = PROP_TYPES

export default withStyles(styles)(InputField)
