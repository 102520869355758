import React, { Component } from 'react'
import SuccessIcon from 'material-ui-icons/CheckCircle'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

const styles = theme => ({
  [theme.breakpoints.up('lg')]: {
    successBox: {
      width: 531,
      border: `1px solid ${theme.colors.darkGreen}`
    }
  },
  successBox: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    borderTop: `1px solid ${theme.colors.darkGreen}`,
    borderBottom: `1px solid ${theme.colors.darkGreen}`,
    top: 0,
    left: 0,
    backgroundColor: theme.colors.lightGreen
  },
  successIcon: {
    display: 'inline-block',
    width: 25,
    padding: 7,
    color: theme.colors.green
  },
  successMsg: {
    display: 'inline-block',
    width: '85%',
    fontSize: 14,
    color: theme.colors.darkGreen,
    letterSpacing: 0.28,
    verticalAlign: 'middle',
    marginBottom: 5,
    minHeight: 28,
  }
})

export class SuccessBox extends Component {

  render() {
    const { classes, message } = this.props

    return (
      <div className={classes.successBox} data-test-id="success-message">
        <span className={classes.successIcon}>
          <SuccessIcon />
        </span>
        <span className={classes.successMsg}>{message}</span>
      </div>
    )
  }
}

/**
 * Props definition
 */
SuccessBox.propTypes = PROP_TYPES

export default withStyles(styles)(SuccessBox)
