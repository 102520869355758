import React, { Component } from 'react'
import classNames from 'classnames'
import Checkbox from 'material-ui/Checkbox'
import { Field } from 'redux-form'
import { FormControlLabel } from 'material-ui/Form'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setRememberUser: PropTypes.func.isRequired,
  rememberUser: PropTypes.bool.isRequired
}

const styles = theme => ({
  checkboxRoot: theme.checkboxRoot,
  checked: theme.checked,
  checkboxLabel: theme.checkboxLabel
})

let cx = classNames.bind(styles)

export class RememberUser extends Component {

  renderCheckbox(props) {
    const {
      classes,
      disableForm,
      input,
      label,
      rememberUser,
      setRememberUser
    } = props

    return (
      <FormControlLabel
        classes={{
          disabled: classes.disabled,
          label: cx(classes.checkboxLabel, {'disabled': disableForm}),
          root: classes.checkboxRoot
        }}
        control={
          <Checkbox
            checked={rememberUser}
            classes={{
              checked: cx(classes.checked, {'disabled': disableForm})
            }}
            data-test-id='remember-user'
            disabled={disableForm}
            {...input}
            label={label}
            onChange={param => setRememberUser(param.target.checked)}
          />
        }
        disabled={disableForm}
        label={label}
      />
    )
  }

  render() {
    const {
      classes,
      disableForm,
      label,
      name,
      setRememberUser,
      rememberUser
    } = this.props

    return (
      <Field
        classes={classes}
        component={this.renderCheckbox}
        disableForm={disableForm}
        label={label}
        name={name}
        setRememberUser={setRememberUser}
        rememberUser={rememberUser} />
    )
  }
}

/**
 * Props definition
 */
RememberUser.propTypes = PROP_TYPES

export default withStyles(styles)(RememberUser)
