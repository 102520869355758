import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import CoreLayout from './coreLayout'
import Messages from '../../constants/messages'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
})

export class LoginLayout extends Component {

  render() {
    const { ...rest } = this.props

    return (
      <CoreLayout applicationTitle={Messages['activeLoginSkin']['StoreCheckoutSkin']['APPLICATION_TITLE']} {...rest}/>
    )
  }
}

LoginLayout.propTypes = PROP_TYPES

const mapStateToProps = state => ({

})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  ),
)(LoginLayout)
