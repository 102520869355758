/* eslint-disable no-useless-escape */
import {
  getAuthClientConfigs,
  normalizeRedirectConfigs } from '../utils/helpers'
import { extractSkinNameFromPath } from '../utils/helpers'
import { handleTransaction } from './transactionManager'
import OktaAuth from '@okta/okta-auth-js'
import Messages from '../constants/messages'
import { incrementCustomMetric, SET_IN_PROGRESS } from './notifications'
import { DefaultSkin, PartnerAccessSkin, StoreCheckoutSkin, SupplierReportsSkin } from '../constants/messages';

// Okta specific constants
const ACTIVE_SESSION = 'ACTIVE'
const AUTH_FAILED_CODE = 'E0000004'

// Login action constants
export const ACCOUNT_LOCKED_OUT = 'login/ACCOUNT_LOCKED_OUT'
export const AUTHENTICATION_FAILED = 'login/AUTHENTICATION_FAILED'
export const EXTRACT_AUTH_CONFIGS_FAILED = 'login/EXTRACT_AUTH_CONFIGS_FAILED'
export const INIT_AUTH_CLIENT = 'login/INIT_AUTH_CLIENT'
export const INIT_AUTH_CLIENT_FAILED = 'login/INIT_AUTH_CLIENT_FAILED'
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
export const PASSWORD_HAS_EXPIRED = 'login/PASSWORD_HAS_EXPIRED'
export const SESSION_CHECKED = 'login/SESSION_CHECKED'
export const SET_REMEMBER_USER = 'login/SET_REMEMBER_USER'
export const SET_SKIP_PASSWORD_WARNING_FUNC = 'login/SET_SKIP_PASSWORD_WARNING_FUNC'
export const SET_USERNAME = 'login/SET_USERNAME'
export const SIGN_IN = 'login/SIGN_IN'
export const UPDATE_SYNC_ERRORS = '@@redux-form/UPDATE_SYNC_ERRORS'
export const FOCUS = '@@redux-form/FOCUS'

const initialState = {
  activeLoginSkinName: DefaultSkin,
  authClient: {},
  authConfigs: {},
  authError: false,
  disableForm: false,
  rememberUser: false,
  skipPasswordWarningFunc: null,
  skipSessionCheck: false,
  username: ''
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        authError: true,
        disableForm: false
      }
    case ACCOUNT_LOCKED_OUT:
    case PASSWORD_HAS_EXPIRED:
      return {
        ...state,
        disableForm: false
      }
    case EXTRACT_AUTH_CONFIGS_FAILED:
    case INIT_AUTH_CLIENT_FAILED:
      return {
        ...state,
        disableForm: true
      }
    case INIT_AUTH_CLIENT:
      return {
        ...state,
        authClient: action.authClient
      }
    case LOCATION_CHANGE:
      const skinPath = extractSkinNameFromPath(action.payload.pathname)
      let skinName = DefaultSkin
      let usingEAS = false
      if(skinPath) {
        switch (skinPath[0]) {
          case 'partner-access-skin':
            skinName = PartnerAccessSkin
            usingEAS = true
            break;
          case 'store-checkout-skin':
            skinName = StoreCheckoutSkin
            usingEAS = true
            break;
          case 'supplier-reports-skin':
            skinName = SupplierReportsSkin
            break;
          default:
            skinName = DefaultSkin
        }
      }

      let configs = getAuthClientConfigs(action.payload.search, usingEAS)
      if(!!configs) {
        return {
          ...state,
          activeLoginSkinName: skinName,
          authConfigs: configs,
          skipSessionCheck: action.payload.pathname.includes('/login/default/')
        }
      } else {
        return {
          ...state,
          activeLoginSkinName: skinName,
          skipSessionCheck: action.payload.pathname.includes('/login/default/')
        }
      }
    case SET_IN_PROGRESS:
      return {
        ...state,
        disableForm: action.inProgress
      }
    case SET_REMEMBER_USER:
      return {
        ...state,
        rememberUser: action.rememberUser
      }
    case SET_SKIP_PASSWORD_WARNING_FUNC:
      return {
        ...state,
        skipPasswordWarningFunc: action.func
      }
    case SET_USERNAME:
      return {
        ...state,
        rememberUser: !!action.username,
        username: action.username
      }
    case FOCUS:
      return {
        ...state,
        authError: false
      }
    default:
      return state
  }
}

export function checkForSession() {
  return (dispatch, getState) => {
    const { authClient, authConfigs } = getState().login

    if(!!authClient.session) {
      return authClient.session.get()
        .then((session) => {
          if(session.status === ACTIVE_SESSION) {
            authClient.token.getWithRedirect(normalizeRedirectConfigs(session, authConfigs))
          } else {
            dispatch({ type: SESSION_CHECKED })
          }
        })
    }
  }
}

export function initializeAuthClient() {
  return (dispatch, getState) => {
    const { authConfigs } = getState().login

    if(Object.keys(authConfigs).length > 0) {
      let authClient = new OktaAuth(authConfigs)

      if(!!authClient) {
        dispatch({ type: INIT_AUTH_CLIENT, authClient })
      } else {
        dispatch({ type: INIT_AUTH_CLIENT_FAILED })
      }
    } else {
      dispatch({ type: EXTRACT_AUTH_CONFIGS_FAILED })
    }
  }
}

export function initFailed() {
  return { type: INIT_AUTH_CLIENT_FAILED }
}

export function sessionChecked() {
  return { type: SESSION_CHECKED }
}

export function setRememberUser(rememberUser) {
  return { type: SET_REMEMBER_USER, rememberUser }
}

export function setUsername() {
  let username = document.cookie.replace(/(?:(?:^|.*;\s*)nauth-login-user\s*\=\s*([^;]*).*$)|^.*$/, '$1')

  return { type: SET_USERNAME, username }
}

export function signIn(credentials) {
  return (dispatch, getState) => {
    const { activeLoginSkinName, authClient, authConfigs, rememberUser } = getState().login
    return authClient.signIn(credentials)
      .then((transaction) => {
        dispatch({ type: SIGN_IN })
        handleTransaction(transaction, activeLoginSkinName, authClient, authConfigs, dispatch, credentials.username, rememberUser)
      })
      .catch((error) => {
        const tags = [error.errorCode].concat(Messages['activeLoginSkin'][activeLoginSkinName]['CUSTOM_METRIC_TAGS'])
        dispatch(incrementCustomMetric('nauth_login.error', tags))
        if(error.errorCode === AUTH_FAILED_CODE) {
          dispatch({ type: AUTHENTICATION_FAILED, activeLoginSkinName })
        }
      })
  }
}

export default reducer
