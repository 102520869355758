import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import {
  checkForSession,
  initializeAuthClient,
  signIn,
  sessionChecked,
  setUsername } from '../../modules/login'
import Messages from '../../constants/messages'
import PropTypes from 'prop-types'
import SCLogin from '../../components/store-checkout-skin/loginForm'
import SessionCheck from '../../components/common/sessionCheck'
import { setSignInInProgress } from '../../modules/notifications'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  authError: PropTypes.bool.isRequired,
  checkForSession: PropTypes.func.isRequired,
  checkSessionInProgress: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  initializeAuthClient: PropTypes.func.isRequired,
  introText: PropTypes.string,
  sessionChecked: PropTypes.func.isRequired,
  setSignInInProgress: PropTypes.func.isRequired,
  setUsername: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInInProgress: PropTypes.bool.isRequired,
  skipSessionCheck: PropTypes.bool.isRequired,
  userName: PropTypes.string
}

const styles = theme => ({
  introText: theme.introText
})

class Login extends Component {

  constructor(props){
    super(props)
    const {
      checkForSession,
      initializeAuthClient,
      sessionChecked,
      setUsername,
      skipSessionCheck } = props

    initializeAuthClient()
    setUsername()
    if(skipSessionCheck) {
      sessionChecked()
    } else {
      checkForSession()
    }
  }

  handleLogin(values) {
    const { setSignInInProgress, signIn } = this.props

    setSignInInProgress(true)
    signIn(values)
  }

  render() {
    const {
      authError,
      checkSessionInProgress,
      classes,
      disableForm,
      introText,
      signInInProgress,
      userName } = this.props

    return (
      <section>
        { checkSessionInProgress ?
          <SessionCheck message={Messages['pageMessages']['CHECKING_FOR_SESSION']} /> :
          <div>
            {introText ? <p className={classes.introText}>{introText}</p> : null}
            <SCLogin
              authError={authError}
              disableForm={disableForm}
              handleFormSubmit={(values) => this.handleLogin(values)}
              initialValues={{username: userName}}
              passwordFieldLabel={Messages['activeLoginSkin']['StoreCheckoutSkin']['PASSWORD_FIELD_LABEL']}
              // set to password if css obfuscation is not supported
              passwordFieldType={'CSS' in window && CSS.supports('-webkit-text-security', 'disc') ? 'tel': 'password'}
              signInInProgress={signInInProgress}
              signInButtonLabel={Messages['activeLoginSkin']['StoreCheckoutSkin']['SIGN_IN_BUTTON_LABEL']}
              userNameFieldLabel={Messages['activeLoginSkin']['StoreCheckoutSkin']['USERNAME_FIELD_LABEL']}
              userNameFieldType="tel" />
          </div>
        }
      </section>
    )
  }
}

Login.propTypes = PROP_TYPES

const mapStateToProps = state => ({
  authError: state.login.authError,
  checkSessionInProgress: state.notifications.checkSessionInProgress,
  disableForm: state.login.disableForm,
  introText: state.clientConfigurations.introText,
  signInInProgress: state.notifications.signInInProgress,
  skipSessionCheck: state.login.skipSessionCheck,
  userName: state.login.username
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkForSession,
  initializeAuthClient,
  sessionChecked,
  setSignInInProgress,
  setUsername,
  signIn
}, dispatch)

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Login)
