import React, { Component } from 'react'
import InfoIcon from 'material-ui-icons/Info'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

const styles = theme => ({
  [theme.breakpoints.up('lg')]: {
    genericBox: {
      width: 531,
      border: `1px solid ${theme.colors.softBlack}`
    }
  },
  genericBox: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    borderTop: `1px solid ${theme.colors.softBlack}`,
    borderBottom: `1px solid ${theme.colors.softBlack}`,
    top: 0,
    left: 0,
    backgroundColor: theme.colors.lightGrey
  },
  infoIcon: {
    display: 'inline-block',
    width: 25,
    padding: 7,
    color: theme.colors.black
  },
  genericMsg: {
    display: 'inline-block',
    width: '85%',
    fontSize: 14,
    color: theme.colors.black,
    letterSpacing: 0.28,
    verticalAlign: 'middle',
    marginBottom: 5,
    minHeight: 28,
  }
})

export class GenericBox extends Component {

  render() {
    const { classes, message } = this.props

    return (
      <div className={classes.genericBox} data-test-id="generic-message">
        <span className={classes.infoIcon}>
          <InfoIcon />
        </span>
        <span className={classes.genericMsg}>{message}</span>
      </div>
    )
  }
}

/**
 * Props definition
 */
GenericBox.propTypes = PROP_TYPES

export default withStyles(styles)(GenericBox)
