/* eslint-disable no-useless-escape */
import AUTH_SERVER_ID from '../constants/authServerIDs'
import OKTA_ENV from '../constants/oktaEnvironments'
import { notNordstromEmail } from './validationRules'

export function setRememberUserCookie(rememberUser, username) {
  if(rememberUser) {
    document.cookie = `nauth-login-user=${username};max-age=` + 60*60*24*365
  } else {
    document.cookie = 'nauth-login-user=; expires=0'
  }
}

export function normalizeRedirectConfigs(session, authConfigs) {
  let configs = {
    scopes: authConfigs.scopes.split(' '),
    sessionToken: session,
    responseType: authConfigs.responseType.trim().indexOf(' ') > 0 ?
      authConfigs.responseType.trim().split(' ') : authConfigs.responseType.trim()
  }
  if(authConfigs.state) {
    configs.state = authConfigs.state
  }
  if(authConfigs.nonce) {
    configs.nonce = authConfigs.nonce
  }

  return configs
}

export function getAuthClientConfigs(search, usingEAS) {
  let env = getUrlParameter('env', search)
  if(!env) {
    return null
  }
  if(usingEAS) {
    env = env + 'EAS'
  }

  let authServerId = getUrlParameter('auth_server_id', search) || AUTH_SERVER_ID[env]

  let configs = {
    authorizeUrl: `${OKTA_ENV[env]}/oauth2/${authServerId}/v1/authorize`,
    clientId: getUrlParameter('client_id', search),
    issuer: `${OKTA_ENV[env]}/oauth2/${authServerId}`,
    env: env,
    redirectUri: getUrlParameter('redirect_uri', search),
    responseType: getUrlParameter('response_type', search) || 'id_token',
    scopes: getUrlParameter('scopes', search) || getUrlParameter('scope', search) || 'openid',
    url: OKTA_ENV[env]
  }
  let state = getUrlParameter('state', search)
  if(state) {
    configs.state = state
  }
  let nonce =  getUrlParameter('nonce', search)
  if(nonce) {
    configs.nonce = nonce
  }

  return authConfigIntegrityCheck(configs) ? configs : null
}

export function isExternalUser(email) {
  // if email contains @nordstrom, the notNordstromEmail will return a string value. if it doesn't contain @nordstrom, it will return undefined
  return notNordstromEmail(email) === undefined ? true : false
}

export function isLocal() {
  return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
}

export function extractSkinNameFromPath(pathname) {
  return pathname.match(/(?:\w+-)*\w*-skin/i)
}

// Code taken from https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
// recursively create an array of one-property objects, then combine them all with Object.assign
export function flattenObject(obj) {
  return Object.assign(
    {},
    ...function _flatten(o) {
      return [].concat(...Object.keys(o)
        .map(k =>
          typeof o[k] === 'object' ?
            _flatten(o[k]) :
            ({[k]: o[k]})
        )
      )
    }(obj)
  )
}

// Parse URL Queries
function getUrlParameter(name, search) {
    name = name && name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    let regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
    let results = regex.exec(search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

// Ensures all of the required auth configs are fetched from url
function authConfigIntegrityCheck(config) {
  const REQUIRED_CONFIGS = ['url', 'clientId', 'redirectUri', 'responseType']

  return REQUIRED_CONFIGS.every((requiredConfig) => {
    return !!config[requiredConfig]
  })
}
