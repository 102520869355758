import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import {
  checkForSession,
  initializeAuthClient,
  signIn,
  sessionChecked,
  setRememberUser,
  setUsername } from '../../modules/login'
import { fetchClientConfigs } from '../../modules/clientConfigurations'
import SessionCheck from '../../components/common/sessionCheck'
import PALoginForm from '../../components/partner-access-skin/loginForm'
import Messages from '../../constants/messages'
import PasswordWarning from '../../components/common/passwordWarning'
import PropTypes from 'prop-types'
import { setSignInInProgress } from '../../modules/notifications'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  checkForSession: PropTypes.func.isRequired,
  checkSessionInProgress: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  fetchClientConfigs: PropTypes.func.isRequired,
  initializeAuthClient: PropTypes.func.isRequired,
  introText: PropTypes.string,
  rememberUser: PropTypes.bool.isRequired,
  sessionChecked: PropTypes.func.isRequired,
  setRememberUser: PropTypes.func.isRequired,
  setSignInInProgress: PropTypes.func.isRequired,
  setUsername: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInButtonLabel: PropTypes.string.isRequired,
  signInInProgress: PropTypes.bool.isRequired,
  skipSessionCheck: PropTypes.bool.isRequired,
  passwordFieldLabel: PropTypes.string.isRequired,
  passwordFieldType: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userNameFieldLabel: PropTypes.string.isRequired,
  userNameFieldType: PropTypes.string.isRequired
}

const styles = theme => ({
  introText: theme.introText
})

class Login extends Component {

  constructor(props){
    super(props)
    const {
      checkForSession,
      fetchClientConfigs,
      initializeAuthClient,
      sessionChecked,
      setUsername,
      skipSessionCheck } = props

    fetchClientConfigs().then(() => {
      initializeAuthClient()
      setUsername()
      if(skipSessionCheck) {
        sessionChecked()
      } else {
        checkForSession()
      }
    })
  }

  handleLogin(values) {
    const { setSignInInProgress, signIn } = this.props

    setSignInInProgress(true)
    const valuesWithOptions = {...values, options: {warnBeforePasswordExpired: true}}
    signIn(valuesWithOptions)
  }

  render() {
    const {
      checkSessionInProgress,
      classes,
      disableForm,
      introText,
      signInInProgress,
      passwordFieldLabel,
      passwordFieldType,
      rememberUser,
      setRememberUser,
      showPasswordWarning,
      signInButtonLabel,
      skipPasswordWarningFunc,
      userName,
      userNameFieldLabel,
      userNameFieldType } = this.props

    return (
      <section>
        { checkSessionInProgress ?
          <SessionCheck message={Messages['pageMessages']['CHECKING_FOR_SESSION']} /> :
          <div>
            {introText ? <p className={classes.introText}>{introText}</p> : null}
            { !showPasswordWarning ?
              <PALoginForm
                disableForm={disableForm}
                handleFormSubmit={(values) => this.handleLogin(values)}
                initialValues={{username: userName}}
                passwordFieldLabel={passwordFieldLabel}
                passwordFieldType={passwordFieldType}
                rememberUser={rememberUser}
                setRememberUser={setRememberUser}
                signInInProgress={signInInProgress}
                signInButtonLabel={signInButtonLabel}
                userNameFieldLabel={userNameFieldLabel}
                userNameFieldType={userNameFieldType} /> :
              <PasswordWarning buttonText={Messages['pageMessages']['SKIP']} message={Messages['activeLoginSkin']['PartnerAccessSkin']['PASSWORD_WARN']} handleClick={skipPasswordWarningFunc}/>
            }
          </div>
        }
      </section>
    )
  }
}

Login.propTypes = PROP_TYPES

const mapStateToProps = state => ({
  checkSessionInProgress: state.notifications.checkSessionInProgress,
  disableForm: state.login.disableForm,
  env: state.login.authConfigs.env,
  introText: state.clientConfigurations.introText,
  passwordFieldLabel: state.clientConfigurations.passwordFieldLabel,
  passwordFieldType: state.clientConfigurations.passwordFieldType,
  rememberUser: state.login.rememberUser,
  showPasswordWarning: state.notifications.showPasswordWarning,
  signInButtonLabel: state.clientConfigurations.signInButtonLabel,
  signInInProgress: state.notifications.signInInProgress,
  skipPasswordWarningFunc: state.login.skipPasswordWarningFunc,
  skipSessionCheck: state.login.skipSessionCheck,
  userName: state.login.username,
  userNameFieldLabel: state.clientConfigurations.userNameFieldLabel,
  userNameFieldType: state.clientConfigurations.userNameFieldType
})

const mapDispatchToProps = dispatch => bindActionCreators({
  checkForSession,
  fetchClientConfigs,
  initializeAuthClient,
  sessionChecked,
  setRememberUser,
  setSignInInProgress,
  setUsername,
  signIn
}, dispatch)

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Login)
