import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
}

const styles = theme => ({
  container: {
    width: '100%'
  }
})

class Callback extends Component {

  render() {
    const { classes, routing } = this.props

    return (
      <div className={classes.container}>
        <h2>Callback called</h2>
        <pre className={classes.code}>{JSON.stringify(routing, null, 2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  routing: state.routing
})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

/**
 * Props definition
 */
Callback.propTypes = PROP_TYPES

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Callback)
