import { CALL_API } from 'redux-api-middleware'
import Messages from '../constants/messages'
import { isLocal } from '../utils/helpers'

export const STYLEBOARDS_CLIENT_IDS = [
  '0oae68n0fnSKZCnvE0h7',
  '0oae9gozlhHRSZuuf0h7',
  '0oav74ldyaUPc6RM62p6',
  '0oav7441ak1WpyWMY2p6'
]

export const STORE_CHECKOUT_CLIENT_IDS = [
  '0oagcqday8pAg0lnp0h7'
]

export const CLIENT_CONFIGS_REQUEST = 'clientConfigurations/CLIENT_CONFIGS_REQUEST'
export const CLIENT_CONFIGS_RECEIVE = 'clientConfigurations/CLIENT_CONFIGS_RECEIVE'
export const CLIENT_CONFIGS_FAILURE = 'clientConfigurations/CLIENT_CONFIGS_FAILURE'
export const LOGIN_CONFIGS_URL_FAILURE = 'clientConfigurations/LOGIN_CONFIGS_URL_FAILURE'
export const SKIP_CLIENT_CONFIGS_FETCH = 'clientConfigurations/SKIP_CLIENT_CONFIGS_FETCH'
export const UPDATE_SYNC_ERRORS = '@@redux-form/UPDATE_SYNC_ERRORS'
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

const initialState = {
  applicationTitle: Messages['defaultAppConfigurations']['APPLICATION_TITLE'],
  containerStyle: {},
  introText: '',
  passwordFieldLabel: Messages['defaultAppConfigurations']['PASSWORD_FIELD_LABEL'],
  passwordFieldType: 'password',
  signInButtonLabel: Messages['defaultAppConfigurations']['SIGN_IN_BUTTON_LABEL'],
  userNameFieldLabel: Messages['defaultAppConfigurations']['USERNAME_FIELD_LABEL'],
  userNameFieldType: 'text'
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const id = parseClientId(action.payload.search)
      if (SELLING_PIN_CLIENTS.includes(id)) {
        return {
          ...state,
          applicationTitle: Messages['defaultAppConfigurations']['APPLICATION_TITLE_SELLING_PIN'],
          passwordFieldLabel: Messages['defaultAppConfigurations']['PASSWORD_FIELD_LABEL_SELLING_PIN'],
          userNameFieldLabel: Messages['defaultAppConfigurations']['USERNAME_FIELD_LABEL_SELLING_PIN']
        }
      } else {
        return state
      }
    case CLIENT_CONFIGS_RECEIVE:
      let config = action.payload
      if(Object.keys(config).length > 0) {
        return {
          ...state,
          applicationTitle: config.languageDictionary.title,
          containerStyle: getContainerStyle(config.clientId),
          introText: config.languageDictionary.introText,
          passwordFieldLabel: config.languageDictionary.passwordFieldLabel,
          passwordFieldType: getPasswordFieldType(config.clientId),
          signInButtonLabel: config.languageDictionary.signInButtonLabel,
          userNameFieldLabel: config.languageDictionary.userNameFieldLabel,
          userNameFieldType: config.userNameFieldType
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export function fetchClientConfigs() {
  if(process.env.NODE_ENV === 'development') {
    return { type: SKIP_CLIENT_CONFIGS_FETCH }
  } else {
    return (dispatch, getState) => {
      const { authConfigs } = getState().login
      const endpoint = `/configs/${authConfigs.clientId}`

      return dispatch({
        [CALL_API]: {
          endpoint: isLocal() ? `http://localhost:8080${endpoint}` : endpoint,
          method: 'GET',
          options: { signal: AbortSignal.timeout(1200) },
          types: [CLIENT_CONFIGS_REQUEST, CLIENT_CONFIGS_RECEIVE, CLIENT_CONFIGS_FAILURE]
        }
      })
    }
  }
}

export const SELLING_PIN_CLIENTS = [
  "0oaps2jvn4O4W7Uqb2p7",
  "0oa234qzhaOCluHL12p7",
  "0oa15uv9cd9Xdy0RM0h8",
  "0oav7441ak1WpyWMY2p6",
  "0oajtbdi6z3Tl02YQ0h7",
  "0oa4p21njlG2EdiLJ2p7",
  "0oav74ldyaUPc6RM62p6"
]

function parseClientId(queryString) {
  const params = new URLSearchParams(queryString)
  return params.get('client_id')
}

function getPasswordFieldType(clientId) {
  const isIOSDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i)
  let results = 'password'

  if(STYLEBOARDS_CLIENT_IDS.includes(clientId) && isIOSDevice) {
    results = 'number'
  } else if(STORE_CHECKOUT_CLIENT_IDS.includes(clientId) && isIOSDevice) {
    results = 'tel'
  }

  return results
}

function getContainerStyle(clientId) {
  let results = {}

  if(STYLEBOARDS_CLIENT_IDS.includes(clientId)) {
    results = { zoom: '1.33' }
  }

  return results
}

export default reducer
