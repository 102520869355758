import { createMuiTheme } from 'material-ui/styles'

const colors = {
  darkGreen: '#087f23',
  darkRed: '#c83136',
  lightGreen: '#d9f7d8',
  lightGrey: '#f1f1f1',
  lighterGrey: '#ced4da',
  lightRed: '#fcf3f4',
  green: '#4caf50',
  grey: '#616161',
  red: '#b91d29',
  softBlack: '#393939',
  trueBlack: '#000000',
  trueWhite: '#ffffff'
}

const theme = createMuiTheme({
  colors: colors,
  checkboxRoot: {
    margin: '0 0 0 -2px'
  },
  checked: {
    color: colors.trueBlack,
    '&.disabled': {
      color: colors.lighterGrey
    }
  },
  checkboxLabel: {
    fontSize: 15,
    '&.disabled': {
      color: colors.lighterGrey
    }
  },
  disabled: {
    color: colors.lighterGrey,
    cursor: 'default'
  },
  formField: {
    margin: '10px 8px',
    width: 'unset',
    display: 'block',
    minWidth: 'unset',
    flexDirection: 'unset'
  },
  helperText: {
    color: colors.red,
    marginTop: 4
  },
  introText: {
    fontSize: 14,
    color: colors.softBlack,
    margin: '0 8px'
  },
  message: {
    display: 'block',
    fontSize: 14,
    paddingTop: 20
  },
  pageMessage: {
    borderTop: `1px solid ${colors.lighterGrey}`,
    borderBottom: `1px solid ${colors.lighterGrey}`,
    padding: '30px 0',
    color: colors.grey,
    width: '100%',
    margin: '30px 0 15px',
    textAlign: 'center'
  },
  progress: {
    color: colors.lighterGrey
  },
  signInButtonContainer: {
    margin: '0 6px 0 8px',
    position: 'relative'
  },
  root: {
    position: 'relative',
    zIndex: 1
  },
  signInButton: {
    width: '100%',
    height: 50,
    color: colors.trueWhite,
    backgroundColor: colors.trueBlack,
    borderRadius: 0,
    marginTop: 10,
    marginBottom: 5,
    '&.inProgress': {
      backgroundColor: colors.grey,
      cursor: 'unset'
    },
    '&:hover': {
      backgroundColor: colors.grey
    }
  },
  signInButtonLabel: {
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: 16,
    '&:disabled': {
      backgroundColor: colors.grey
    }
  },
  signInButtonDisabled: {
    backgroundColor: colors.grey
  },
  signInButtonProgress: {
    color: colors.trueWhite,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -12
  },
  textFieldRoot: {
    padding: 0,
    'label + &': {
      marginTop: 24
    }
  },
  textFieldInput: {
    backgroundColor: colors.trueWhite,
    border: `1px solid ${colors.lighterGrey}`,
    fontSize: 16,
    padding: '10px 12px',
    height: 22,
    width: '100%',
    transition: 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&.error': {
      borderColor: colors.red
    },
    '&.hiddenNumeric': {
      '-webkit-text-security': 'disc'
    }
  },
  textFieldFormLabel: {
    fontSize: 20,
    color: colors.trueBlack,
    paddingLeft: 5,
    top: 3,
    '&.disabled': {
      color: colors.grey
    },
    '&.error': {
      color: colors.red,
      background: {
        image:'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAh1BMVEUAAAD/AAD/VVX/SST/QEDvQDD0QDX3Qjr3QDjyQTT1RTb1QzXzQjXzRTX0RDX0QzfzQzXzQzf0Qzb0QjX0Qzb0QzX1QjbzRDb0Qzf0QzbzQjfzQzb0QzX0Qzf0RDbzQzb0Qzb0Qzb1RDb0Qzb0Qzb0Qjb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb///8ZSaPmAAAAK3RSTlMAAQMHCBAYHyAnNDU+P0RFV1hfYHJzf4CMjZqbp6i1xc7P297p6vHy9vr9AEHu3gAAAAFiS0dELLrdcasAAACHSURBVBgZrcFXFoIwAEXBiyL23hso2N/+92cgHDWSP53hJ8MBXvU0reMzl2Z4RFfpElG1lbGhovuQ8ejwJUhUSAJcY5VGOMJMEkjKQj4tZYCMBR9aNxkg49bkba8cKLfjpS9Hj1LtKMehhjWVBbImFBpnWSDr1CC3VsUKo31Xxb0NxPKI+aMnI9EhMfRGPZMAAAAASUVORK5CYII=")',
        repeat: 'no-repeat',
        position: ['left center']
      },
      paddingLeft: 30
    }
  },
  typography: {
    fontFamily: 'BrandonText'
  }
})

export default theme
