import React, { Component } from 'react'
import Button from 'material-ui/Button'
import { CircularProgress } from 'material-ui/Progress'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  signInButtonLabel: PropTypes.string.isRequired,
  signInInProgress: PropTypes.bool.isRequired
}

const styles = theme => ({
  signInButton: Object.assign(theme.signInButton,
    { // Overwritting default
      height: 55,
      marginTop: 43,
      '&:hover': {
        backgroundColor: '#000000'
      }
    }),
  signInButtonContainer: Object.assign(theme.signInButtonContainer,
    {
      margin: '10px auto',
      width: 256
    }),
  signInButtonLabel: Object.assign(theme.signInButtonLabel,
    {
      fontSize: 26
    }),
  signInButtonProgress: theme.signInButtonProgress
})

let cx = classNames.bind(styles)

export class SignInButton extends Component {

  render() {
    const {
      classes,
      disableForm,
      signInButtonLabel,
      signInInProgress
    } = this.props

    return (
      <div className={classes.signInButtonContainer}>
        <Button
          className={cx({'inProgress': signInInProgress})}
          classes={{
            disabled: classes.signInButtonDisabled,
            label: classes.signInButtonLabel,
            root: classes.signInButton
          }}
          data-test-id="sign-in-button"
          disabled={disableForm}
          type="submit">
          {signInInProgress ? '' : signInButtonLabel}
        </Button>
        {signInInProgress &&
          <CircularProgress
            data-test-id="sign-in-progress"
            size={40}
            className={classes.signInButtonProgress} />}
      </div>
    )
  }
}

/**
 * Props definition
 */
SignInButton.propTypes = PROP_TYPES

export default withStyles(styles)(SignInButton)
