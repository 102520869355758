import { CALL_API } from 'redux-api-middleware'
export const NO_ONBOARD_DATA = 'onboard/NO_ONBOARD_DATA '
export const POST_AUDIT_REQUEST = 'onboard/POST_AUDIT_REQUEST'
export const POST_AUDIT_RECEIVE = 'onboard/POST_AUDIT_RECEIVE'
export const POST_AUDIT_FAILURE = 'onboard/POST_AUDIT_FAILURE'
export const SET_IS_VALIDATED = 'onboard/SET_IS_VALIDATED'
export const SET_OKTA_DATA = 'onboard/SET_OKTA_DATA'

const initialState = {
  isValidated: false,
  oktaEmail: null,
  oktaFullName: null,
  oktaLink: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OKTA_DATA:
      return {
        ...state,
        oktaEmail: action.oktaEmail,
        oktaFullName: action.oktaFullName,
        oktaLink: action.oktaLink
      }
      case SET_IS_VALIDATED:
      return {
        ...state,
        isValidated: action.isValidated
      }
    default:
      return state
  }
}

export function postAudit(url) {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        body: JSON.stringify({url}),
        endpoint: 'https://ifk8vy2rj7.execute-api.us-west-2.amazonaws.com/prod/audit',
        method: 'POST',
        types: [POST_AUDIT_REQUEST, POST_AUDIT_RECEIVE, POST_AUDIT_FAILURE]
      }
    })
  }
}

export function setIsValidated(isValidated) {
  return { type: SET_IS_VALIDATED, isValidated }
}

export function setOktaData(oktaEmail, oktaFullName, oktaLink) {
  return { type: SET_OKTA_DATA, oktaEmail, oktaFullName, oktaLink }
}

export default reducer
