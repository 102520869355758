import React, { Component } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import TextField from 'material-ui/TextField'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  autoFocus: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validate: PropTypes.array
}

const styles = theme => ({
  disabled: theme.disabled,
  formField: theme.formField,
  helperText: theme.helperText,
  textFieldRoot: theme.textFieldRoot,
  textFieldInput: theme.textFieldInput,
  textFieldFormLabel: theme.textFieldFormLabel
})

let cx = classNames.bind(styles)

export class InputField extends Component {

  renderField(props) {
    const {
      autoFocus,
      classes,
      disableForm,
      input,
      label,
      type,
      meta: {touched, error}
    } = props
    const isMobileDevice = navigator.userAgent.match(/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i)

    return (
      <TextField
        autoFocus={!isMobileDevice && autoFocus}
        className={classes.formField}
        data-test-id={input.name}
        disabled={disableForm}
        fullWidth={true}
        helperText={touched && error ? error : ""}
        FormHelperTextProps={{
          className: classes.helperText
        }}
        {...input}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.textFieldRoot,
            input: cx(
              classes.textFieldInput,
              {'error': touched && error},
              {'hiddenNumeric': input.name === 'password' && type === 'number'}
            )
          },
        }}
        InputLabelProps={{
          className: cx(classes.textFieldFormLabel, {'disabled': disableForm}, {'error': touched && error}),
          shrink: true
        }}
        onBlur={() => {}}
        label={label}
        type={type}
      />
    )
  }

  render() {
    const {
      autoFocus,
      classes,
      disableForm,
      label,
      name,
      type,
      validate
    } = this.props

    return (
      <Field
        autoFocus={autoFocus}
        classes={classes}
        disableForm={disableForm}
        component={this.renderField}
        label={label}
        name={name}
        type={type}
        validate={validate} />
    )
  }
}

/**
 * Props definition
 */
InputField.propTypes = PROP_TYPES

export default withStyles(styles)(InputField)
