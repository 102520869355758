const styles = theme => ({
  '@global': {
    html: {
      background: '#ffffff',
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
      overflowY: 'hidden',
      '@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape)': {
        transform: 'rotate(90deg)',
        transformOrigin: 'left top',
        position: 'absolute',
        left: '90%',
        top: '19%'
      }
    },
    body: {
      overflowY: 'hidden',
      fontFamily: 'BrandonText',
      margin: 0,
      position: 'relative'
    },
    h1: {
      fontSize: 28,
      letterSpacing: 'normal',
      color: '#000000',
      textAlign: 'center',
      fontWeight: 'normal',
      marginTop: 0,
      marginBottom: 78
    }
  }
})

export default styles
