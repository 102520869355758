import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Messages from '../constants/messages'
import Paper from 'material-ui/Paper'
import Typography from 'material-ui/Typography'
import withStyles from 'material-ui/styles/withStyles'

const styles = theme => ({
  bigHeader: {
    marginTop: '1em',
    marginBottom:'1em',
    textTransform: 'none'
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '30em',
    height: '5em'
  },
  typography: {
    width: '90%',
    marginBottom: '.5em',
    marginTop: '.5em'
  }
})

class ErrorPage extends Component {
  render() {
    const { classes } = this.props
     
    return (
      <section>
        <h1 data-test-id='error-h1' className={classes.bigHeader}>{Messages['pageMessages']['ERROR_HEADER']}</h1>
        <Paper className={classes.paper}>
          <Typography data-test-id='service-desk-typography'className={classes.typography}>{Messages['pageMessages']['SERVICE_DESK_MSG']}</Typography>
        </Paper>
      </section>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(ErrorPage)
