import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import Index from './pages/index'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import store, { history } from './store'
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from 'material-ui/styles';
import expand from 'jss-expand'
//Might need to clean up our jss usage ie. getContext(), I don't know enough about it to do that right now
//but this was the only way I could get the background-image (error symbol) to work.
const generateClassName = createGenerateClassName();
const jss = create(jssPreset())
jss.use(expand())

const target = document.querySelector('#root')

render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <Index />
        </div>
      </ConnectedRouter>
    </Provider>
  </JssProvider>,
  target
)
