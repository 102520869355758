import { createMuiTheme } from 'material-ui/styles'
import defaultTheme from '../default/theme'

const theme = createMuiTheme(Object.assign({}, defaultTheme,
  // Overwritting the default theme
  {
    signInButton: {
      width: '100%',
      height: 55,
      color: '#ffffff',
      backgroundColor: '#000000',
      borderRadius: 27.5,
      marginTop: 10,
      marginBottom: 5,
      '&.inProgress': {
        backgroundColor: '#000000 !important',
        cursor: 'unset'
      },
      '&:hover': {
        backgroundColor: '#616161'
      }
    },
    signInButtonProgress: {
      color: '#ffffff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 0,
      marginLeft: -19
    }
  }))

export default theme
