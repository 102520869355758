// Login Skin Names
export const DefaultSkin = 'DefaultSkin'
export const StoreCheckoutSkin = 'StoreCheckoutSkin'
export const SupplierReportsSkin = 'SupplierReportsSkin'
export const PartnerAccessSkin = 'PartnerAccessSkin'

const messages = {
  authClientErrors: {
    EXTRACT_AUTH_CONFIGS_FAILED: 'There was an issue with extracting configurations from NAuth Service\'s login url',
    EXTRACT_ONBOARD_DATA_FAILED: 'There was an issue with extracting configurations from the login url. Please try again. If you\'re still unable to sign in, contact the Service Desk.',
    INIT_AUTH_CLIENT_FAILED: 'There was an issue with initializing the auth client'
  },
  clientLoginConfigErrors: {
    LOGIN_CONFIGS_FAILURE: 'There was an issue with getting client login configurations, using defaults',
    LOGIN_CONFIGS_URL_FAILURE: 'There was an issue with the login configs url, using defaults'
  },
  defaultAppConfigurations: {
    APPLICATION_TITLE: 'Sign In',
    PASSWORD_FIELD_LABEL: 'Password',
    SIGN_IN_BUTTON_LABEL: 'Sign In',
    USERNAME_FIELD_LABEL: 'Email',
    APPLICATION_TITLE_SELLING_PIN: 'SIGN IN',
    PASSWORD_FIELD_LABEL_SELLING_PIN: 'Selling PIN',
    USERNAME_FIELD_LABEL_SELLING_PIN: 'Employee number'
  },
  fieldErrors: {
    REQUIRED: 'Required',
    EMAIL: 'Enter a valid email',
    NORD_EMAIL: 'Please reset password in the Self-Service Password Portal'
  },
  pageMessages: {
    TITLE_403: '403',
    ERROR_403: 'Please go to the login page for help with a forgotten password.',
    ACCOUNT_LOCKED_OUT: 'Too many unsuccessful sign-in attempts. Try again in a few minutes. If you’re still unable to sign in, contact the Service Desk.',
    BACK: 'BACK',
    CHECKING_FOR_SESSION: 'Checking session token',
    CONFIRM_USERNAME: 'Confirm username',
    ERROR_HEADER:'ERROR',
    EMAIL_FIELD_LABEL: 'Email',
    FOLLOW_STEPS: 'Follow three easy steps to learn your username for Nordstrom Single Sign-On',
    FOOTER_NOTE: 'Please note: Your Nordstrom Single Sign-On password is the same as your Nordstrom computer login password, if you change one password the other is changed as well.',
    FORGOT_PASSWORD_EMAIL_RESET_BUTTON: 'Reset password via email',
    FORGOT_PASSWORD_EMAIL_SUCCESS: "Email Sent",
    FORGOT_PASSWORD_EMAIL_FAILURE: "Please re-enter your email and try again. If the issue persists, please contact your admin to reset your password.",
    FORGOT_PASSWORD_LINK: 'Forgot password?',
    FORGOT_PASSWORD_TITLE: 'Forgot Password',
    HELLO: 'Hello, ',
    LEARN_YOUR_USERNAME: 'Learn your username',
    NEXT: 'NEXT',
    SERVICE_DESK_MSG: 'Uh-oh, something went wrong. Please contact the Service Desk for help, 206-233-4501.',
    SIGN_IN_LINK: '\u2190 Sign in',
    SKIP: 'SKIP',
    TEXT_FIELD_HELPER: 'Please enter the username displayed in step 2',
    TITLE: 'Nordstrom SSO Onboarding',
    UNKNOWN_STEP: 'Unknown Step',
    USERNAME: 'Username',
    VENDORS_TERMS_MSG1: 'By signing in to your account, you agree to our ',
    VENDORS_TERMS_LINK: 'Supplier Reports User Agreement',
    VENDORS_TERMS_MSG2: '.',
    WRITE_DOWN_USERNAME: 'Be sure to write down your username and store it in a safe place. You will need it in the future.',
    YOUR_USERNAME: 'Your username'
  },
  activeLoginSkin: {
    DefaultSkin: {
      AUTHENTICATION_FAILED: 'Couldn\'t sign in. Check your credentials and try again. If you\'re still unable to sign in, contact the Service Desk.',
      PASSWORD_HAS_EXPIRED: 'Your password has expired, please reset password in Self-Service Password Portal.',
      PASSWORD_WARN: "Your password will expire soon. You can reset your password in Self-Service Password Portal.",
      CUSTOM_METRIC_TAGS: ['login-skin:default']
    },
    PartnerAccessSkin: {
      AUTHENTICATION_FAILED: 'Couldn\'t sign in. Check your credentials and try again. If you\'re still unable to sign in, contact the Service Desk.',
      PASSWORD_HAS_EXPIRED: 'Your password has expired. Please reset your password using the \'Forgot password\' link below or contact your admin',
      PASSWORD_WARN: "Your password will expire soon. You can reset your password from the profile menu in Partner Access.",
      CUSTOM_METRIC_TAGS: ['login-skin:partner-access']
    },
    SupplierReportsSkin: {
      AUTHENTICATION_FAILED: 'Couldn\'t sign in. Check your credentials and try again. If you\'re still unable to sign in, contact the Service Desk.',
      PASSWORD_HAS_EXPIRED: 'Your password has expired. Please reset your password using the \'Forgot password\' link below or contact your admin',
      PASSWORD_WARN: "Your password will expire soon. You can reset your password from the account link in Supplier Reports.",
      CUSTOM_METRIC_TAGS: ['login-skin:supplier-reports']
    },
    StoreCheckoutSkin: {
      APPLICATION_TITLE: 'Employee sign in',
      AUTHENTICATION_FAILED: 'We don\'t recognize your credentials. Try again.',
      CUSTOM_METRIC_TAGS: ['login-skin:store-checkout', 'app_group:pos', 'appid:APP01572', 'app_name:store-checkout'],
      PASSWORD_FIELD_LABEL: 'Enter selling PIN',
      SIGN_IN_BUTTON_LABEL: 'Sign in',
      USERNAME_FIELD_LABEL: 'Enter employee number'
    }
  },
  tooltips: {
    FORGOT_PASSWORD_TOOLTIP: 'This will redirect the user to Okta for the password reset, please return to Supplier Reports when done'
  },
}

export default messages
