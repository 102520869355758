import React, { Component } from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import InputField from '../common/inputField'
import { Link } from 'react-router-dom'
import Messages from '../../constants/messages'
import PropTypes from 'prop-types'
import RememberUser from '../common/rememberUser'
import SignInButton from '../common/signInButton'
import { required } from '../../utils/validationRules'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  passwordFieldLabel: PropTypes.string.isRequired,
  passwordFieldType: PropTypes.string.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  setRememberUser: PropTypes.func.isRequired,
  signInInProgress: PropTypes.bool.isRequired,
  signInButtonLabel: PropTypes.string.isRequired,
  userNameFieldLabel: PropTypes.string.isRequired,
  userNameFieldType: PropTypes.string.isRequired
}

const styles = theme => ({
  forgotPasswordLink: {
    color: 'black',
    fontSize: 15,
    display: 'inline-block',
    margin: '15px 10px 5px'
  },
  terms: {
    display: 'block',
    fontSize: 13,
    margin: 10
  },
  termsLink: {
    color: 'black'
  },
  tooltip: {
    fontSize: 14
  }
})

export class LoginForm extends Component {

  render() {
    const {
      classes,
      disableForm,
      handleFormSubmit,
      handleSubmit,
      initialValues,
      passwordFieldLabel,
      passwordFieldType,
      rememberUser,
      setRememberUser,
      signInInProgress,
      signInButtonLabel,
      userNameFieldLabel,
      userNameFieldType } = this.props

    return (
      <form className="login-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <InputField
          autoFocus={initialValues.username === ''}
          disableForm={disableForm}
          label={userNameFieldLabel}
          name="username"
          type={userNameFieldType}
          validate={[required]} />
        <InputField
          autoFocus={initialValues.username !== ''}
          disableForm={disableForm}
          label={passwordFieldLabel}
          name="password"
          type={passwordFieldType}
          validate={[required]} />
        <RememberUser
          disableForm={disableForm}
          label={`Remember ${userNameFieldLabel.toLowerCase()}`}
          name="rememberUser"
          setRememberUser={setRememberUser}
          rememberUser={rememberUser} />
        <SignInButton
          disableForm={disableForm}
          signInButtonLabel={signInButtonLabel}
          signInInProgress={signInInProgress} />
        <Link 
          data-test-id="forgot-password-link"
          to="/forgot-password"
          className={classes.forgotPasswordLink}>
        {Messages['pageMessages']['FORGOT_PASSWORD_LINK']}
        </Link>
      </form>
    )
  }
}

/**
 * Props definition
 */
LoginForm.propTypes = PROP_TYPES

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'login'
  })
)(LoginForm)
