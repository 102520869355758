import { CALL_API } from 'redux-api-middleware'
import { isLocal } from '../utils/helpers'
export const FORGOT_PASSWORD_EMAIL_SEND_REQUEST = 'forgotPassword/FORGOT_PASSWORD_EMAIL_SEND_REQUEST'
export const FORGOT_PASSWORD_EMAIL_SEND_RECEIVE = 'forgotPassword/FORGOT_PASSWORD_EMAIL_SEND_RECEIVE'
export const FORGOT_PASSWORD_EMAIL_SEND_FAILURE = 'forgotPassword/FORGOT_PASSWORD_EMAIL_SEND_FAILURE'

const initialState = {
  isSubmitting: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_EMAIL_SEND_REQUEST:
      return {
        ...state,
        isSubmitting: true
      }
    case FORGOT_PASSWORD_EMAIL_SEND_RECEIVE:
    case FORGOT_PASSWORD_EMAIL_SEND_FAILURE:
      return {
        ...state,
        isSubmitting: false
      }
    default:
      return state
  }
}

export function sendForgotPasswordEmail(email, appClientId) {
  const encodedEmail = encodeURIComponent(email)
  const authZEndpoint = `/api/v1/authz/users/${encodedEmail}/email/forgot_password?appClientId=${appClientId}`
  return {
    [CALL_API]: {
      endpoint: isLocal() ? `http://localhost:8080${authZEndpoint}` : authZEndpoint,
      method: 'GET',
      types: [FORGOT_PASSWORD_EMAIL_SEND_REQUEST, FORGOT_PASSWORD_EMAIL_SEND_RECEIVE, FORGOT_PASSWORD_EMAIL_SEND_FAILURE]
    }
  }
}

export default reducer
