import React, { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'
import { CoreLayout } from '../common/coreLayout';

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
  coreContainer: {
    minWidth: '400px'
  },
  root: theme.root,
  [theme.breakpoints.up('xs')]: {
    desktopOnly: {
      display: 'none'
    }
  },
  nordstromHeader: {
    background: '#F9F9F9',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
    position: 'relative'
  },
  nordstromLogo: {
    width: '47%',
    minWidth: 143,
    maxWidth: 220
  }
})

export class OnboardLayout extends Component {

  render() {
    const { ...rest } = this.props

    return (
      <CoreLayout {...rest} />
    )
  }
}

OnboardLayout.propTypes = PROP_TYPES

export default compose(
  withStyles(styles),
)(OnboardLayout)
