'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');
// request timeout polyfill
require('abortcontroller-polyfill')
if (typeof AbortSignal !== 'undefined' && !AbortSignal.timeout) {
  AbortSignal.timeout = function(ms) {  
    const controller = new AbortController();  
    setTimeout(() => controller.abort(), ms);  
    return controller.signal;  
  };  
}  

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}


var keys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', keysRe = new RegExp('[^' + keys + ']');

global.atob = function atob(input) {
  var output = [], buffer, bufferB, chrs, index = 0, indexB, length = input.length;

  if ((keysRe.test(input)) || (/=/.test(input) && (/=[^=]/.test(input) || /={3}/.test(input)))) {
    throw new Error('Invalid base64 data');
  }

  if (length % 4 > 0) {
    input += Array(4 - length % 4 + 1).join("=");
    length = input.length;
  }

  while (index < length) {
    for (bufferB = [], indexB = index; index < indexB + 4;) {
      bufferB.push(keys.indexOf(input.charAt(index++)));
    }

    buffer = (bufferB[0] << 18) + (bufferB[1] << 12) + ((bufferB[2] & 63) << 6) + (bufferB[3] & 63);

    chrs = [(buffer & (255 << 16)) >> 16, bufferB[2] === 64 ? -1 : (buffer & (255 << 8)) >> 8, bufferB[3] === 64 ? -1 : buffer & 255];

    for (indexB = 0; indexB < 3; ++indexB) {
      if (chrs[indexB] >= 0 || indexB === 0) {
        output.push(String.fromCharCode(chrs[indexB]));
      }
    }
  }

  return output.join('');
};
  

require('core-js/es6/map');
require('core-js/es6/set');
require('es7-array.prototype.includes');
require('string.prototype.includes');
