import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'
import Stepper, { Step, StepLabel, StepContent } from 'material-ui/Stepper'
import Button from 'material-ui/Button'
import Typography from 'material-ui/Typography'
import ValidationTextField from './validationTextField'
import Messages from '../../constants/messages'

const styles = theme => ({
  button: {
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    display: 'flex',
    marginTop: '1em'
  },
  typographyPadding: {
    paddingTop: '10px',
    width: '250px'
  }
})

function getSteps() {
  return [Messages['pageMessages']['LEARN_YOUR_USERNAME'], Messages['pageMessages']['YOUR_USERNAME'], Messages['pageMessages']['CONFIRM_USERNAME']]
}

function getStepContent(step, oktaEmail) {
  switch (step) {
    case 0:
      return Messages['pageMessages']['WRITE_DOWN_USERNAME']
    case 1:
      return `${oktaEmail}`
    default:
      return Messages['pageMessages']['UNKNOWN_STEP']
  }
}

class VerticalStepper extends React.Component {
  state = {
    activeStep: 0,
    fieldError: false
  }

  handleNext = () => {
    const { isValidated } = this.props
    if (this.state.activeStep !== 2) {
      this.setState({
        activeStep: this.state.activeStep + 1,
        fieldError: false
      })
    } else {
      if (isValidated) {
        this.setState({
          fieldError: false,
        })
        window.location = this.props.oktaLink 
      } else {
        this.setState({
          fieldError: true,
        })
      }
    }
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    })
  }

  render() {
    const { classes, isValidated, oktaEmail, setIsValidated } = this.props
    const steps = getSteps()
    const { activeStep, fieldError } = this.state

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  { activeStep !== 2 ? 
                    <Typography className={classes.typographyPadding} data-test-id='step-content'>{getStepContent(index, oktaEmail)}</Typography> :
                    <ValidationTextField fieldError={fieldError} isValidated={isValidated} oktaEmail={oktaEmail} setIsValidated={setIsValidated}/> }
                    <div className={classes.actionsContainer}>
                    {activeStep === 2 ? <Button
                        onClick={this.handleBack}
                        className={classes.button}>
                          { Messages['pageMessages']['BACK'] }
                      </Button> : null}
                    <div>
                      <Button
                        data-test-id='stepper-next-button'
                        variant='raised'
                        color='primary'
                        onClick={this.handleNext}
                        className={classes.button}>
                          { Messages['pageMessages']['NEXT'] }
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
      </div>
    )
  }
}

VerticalStepper.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(VerticalStepper)