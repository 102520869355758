import {
  ACCOUNT_LOCKED_OUT,
  PASSWORD_HAS_EXPIRED,
  SET_SKIP_PASSWORD_WARNING_FUNC } from './login'
import {
  normalizeRedirectConfigs,
  setRememberUserCookie } from '../utils/helpers'
import {
  incrementCustomMetric,
  showPasswordWarning
} from './notifications'
import { isExternalUser } from '../utils/helpers'
import Messages from '../constants/messages'

export const LOCKED_OUT = 'LOCKED_OUT'
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED'
export const PASSWORD_WARN = 'PASSWORD_WARN'
export const SUCCESS = 'SUCCESS'

export function handleTransaction(transaction, activeLoginSkinName, authClient, authConfigs, dispatch, username, rememberUser) {
  const tags = [`transaction-status:${transaction.status}`].concat(Messages['activeLoginSkin'][activeLoginSkinName]['CUSTOM_METRIC_TAGS'])
  tags.push(`client-id:${authConfigs.clientId}`)
  dispatch(incrementCustomMetric('nauth_login', tags))
  switch (transaction.status) {
    case LOCKED_OUT:
      return dispatch({ type: ACCOUNT_LOCKED_OUT })
    case SUCCESS:
        setRememberUserCookie(rememberUser, username)
        authClient.token.getWithRedirect(normalizeRedirectConfigs(transaction.sessionToken, authConfigs))
      break
    case PASSWORD_WARN:
      return transaction.skip()
        .then((transaction) => {
          if (isExternalUser(transaction.user.profile.login)) {
            dispatch(showPasswordWarning(true))
            setTimeout(() => {
              passwordWarningTimeout(rememberUser, username, authClient, transaction, authConfigs)
            }, 5000)
            const boundFunction = () => passwordWarningTimeout(rememberUser, username, authClient, transaction, authConfigs)
            dispatch({type: SET_SKIP_PASSWORD_WARNING_FUNC, func: boundFunction})
          } else {
            setRememberUserCookie(rememberUser, username)
            authClient.token.getWithRedirect(normalizeRedirectConfigs(transaction.sessionToken, authConfigs))
          }
        })
    case PASSWORD_EXPIRED:
      return dispatch({ type: PASSWORD_HAS_EXPIRED, activeLoginSkinName })
    default:
      console.error(`${transaction.status} hasn't been implemeted yet`)
  }
}

const passwordWarningTimeout = (rememberUser, username, authClient, transaction, authConfigs) => {
  setRememberUserCookie(rememberUser, username)
  authClient.token.getWithRedirect(normalizeRedirectConfigs(transaction.sessionToken, authConfigs))
}
