import React, { Component } from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import InputField from '../common/inputField'
import PropTypes from 'prop-types'
import RememberUser from '../common/rememberUser'
import SignInButton from '../common/signInButton'
import { required } from '../../utils/validationRules'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  passwordFieldLabel: PropTypes.string.isRequired,
  passwordFieldType: PropTypes.string.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  setRememberUser: PropTypes.func.isRequired,
  signInInProgress: PropTypes.bool.isRequired,
  signInButtonLabel: PropTypes.string.isRequired,
  userNameFieldLabel: PropTypes.string.isRequired,
  userNameFieldType: PropTypes.string.isRequired
}

const styles = theme => ({

})

export class LoginForm extends Component {

  render() {
    const {
      disableForm,
      handleFormSubmit,
      handleSubmit,
      initialValues,
      passwordFieldLabel,
      passwordFieldType,
      rememberUser,
      setRememberUser,
      signInInProgress,
      signInButtonLabel,
      userNameFieldLabel,
      userNameFieldType } = this.props

    return (
      <form className="login-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <InputField
          autoFocus={initialValues.username === ''}
          disableForm={disableForm}
          label={userNameFieldLabel}
          name="username"
          type={userNameFieldType}
          validate={[required]} />
        <InputField
          autoFocus={initialValues.username !== ''}
          disableForm={disableForm}
          label={passwordFieldLabel}
          name="password"
          type={passwordFieldType}
          validate={[required]} />
        <RememberUser
          disableForm={disableForm}
          label={`Remember ${userNameFieldLabel.toLowerCase()}`}
          name="rememberUser"
          setRememberUser={setRememberUser}
          rememberUser={rememberUser} />
        <SignInButton
          disableForm={disableForm}
          signInButtonLabel={signInButtonLabel}
          signInInProgress={signInInProgress} />
      </form>
    )
  }
}

/**
 * Props definition
 */
LoginForm.propTypes = PROP_TYPES

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'login'
  })
)(LoginForm)
