import React, { Component } from 'react'
import ErrorIcon from 'material-ui-icons/Warning'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

const styles = theme => ({
  [theme.breakpoints.up('lg')]: {
    errorBox: {
      width: 531,
      border: `1px solid ${theme.colors.darkRed}`
    }
  },
  errorBox: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    borderTop: `1px solid ${theme.colors.darkRed}`,
    borderBottom: `1px solid ${theme.colors.darkRed}`,
    top: 0,
    left: 0,
    backgroundColor: theme.colors.lightRed
  },
  errorIcon: {
    display: 'inline-block',
    width: 25,
    padding: 7
  },
  errorMsg: {
    display: 'inline-block',
    width: '85%',
    fontSize: 14,
    color: theme.colors.red,
    letterSpacing: 0.28,
    verticalAlign: 'middle',
    marginBottom: 5,
    minHeight: 28,
  }
})

export class ErrorBox extends Component {

  render() {
    const { classes, message } = this.props

    return (
      <div className={classes.errorBox} data-test-id="error-message">
        <span className={classes.errorIcon}>
          <ErrorIcon color="error" />
        </span>
        <span className={classes.errorMsg}>{message}</span>
      </div>
    )
  }
}

/**
 * Props definition
 */
ErrorBox.propTypes = PROP_TYPES

export default withStyles(styles)(ErrorBox)
