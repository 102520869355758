import clientConfigurations from './clientConfigurations'
import { combineReducers } from 'redux'
import forgotPassword from './forgotPassword'
import login from './login'
import notifications from './notifications'
import onboard from './onboard'
import { reducer as form } from 'redux-form'
import { routerReducer as routing } from 'react-router-redux'

export default combineReducers({
  clientConfigurations,
  forgotPassword,
  form,
  login,
  notifications,
  onboard,
  routing
})
