import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Messages from '../constants/messages'
import Paper from 'material-ui/Paper'
import PropTypes from 'prop-types'
import { setNoOnboardData } from '../modules/notifications'
import { postAudit, setIsValidated, setOktaData } from '../modules/onboard'
import Typography from 'material-ui/Typography'
import withStyles from 'material-ui/styles/withStyles'
import VerticalStepper from '../components/onboard/verticalStepper'

const PROP_TYPES = {
  setIsValidated: PropTypes.func.isRequired,
  setNoOnboardData: PropTypes.func.isRequired,
  setOktaData: PropTypes.func.isRequired
}

const styles = theme => ({
  introText: theme.introText,
  bigHeader: {
    fontSize: '1.25em',
    letterSpacing: '1px',
    marginTop: '1em',
    marginBottom:0,
    textTransform: 'none'
  },
  smallHeader: {
    fontSize: '1.25em',
    letterSpacing: '1px',
    marginTop: '.2em',
    textAlign: 'center',
    textTransform: 'none'
  },
  centerStepper: {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '35em',
    paddingTop: '1em',
    paddingBottom: '1em'
  },
  footerTypography: {
    textAlign: 'center',
    maxWidth: '40em',
    margin: 'auto',
    marginTop: '1em'
  }
})

function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function parseCode(code) {
  const b64String = code.substring(12)
  return window.atob(b64String.replace(/\s/g, ''))
}

function getOktaValues(string) {
  let returnObj = {}
  string.split('&').forEach((keyValuePair) => {
    let key = keyValuePair.substring(0, keyValuePair.indexOf('='))
    let value = keyValuePair.substring(keyValuePair.indexOf('=') + 1)
    returnObj[key] = value
  })
  return returnObj
}


class Onboard extends Component {
  componentWillMount() {
    const { postAudit } = this.props
    let code, oktaEmail, oktaFullName, oktaLink
    document.title = Messages['pageMessages']['TITLE']
    code = getParameterByName('code')
    if (code) {
      try {
        let valuesString = parseCode(code)
        const oktaValuesObj = getOktaValues(valuesString)
        oktaEmail = oktaValuesObj.o_email
        oktaFullName = oktaValuesObj.o_name
        oktaLink = oktaValuesObj.o_link
        if (oktaLink && oktaEmail && oktaFullName) {
          this.props.setOktaData(oktaEmail, oktaFullName, oktaLink)
        } else {
          postAudit(window.location.href)
          .then(() => {
            window.location = '/error'
          })
          .catch(() => {
            window.location = '/error'
          })
        }
      } catch (error) {
        postAudit(window.location.href)
        .then(() => {
          window.location = '/error'
        })
        .catch(() => {
          window.location = '/error'
        })
      }
    } else {
      oktaEmail = getParameterByName('o_email')
      oktaFullName = getParameterByName('o_name')
      oktaLink = getParameterByName('o_link')
      if (oktaLink && oktaEmail && oktaFullName) {
        this.props.setOktaData(oktaEmail, oktaFullName, oktaLink)
      } else {
        postAudit(window.location.href)
        .then(() => {
          window.location = '/error'
        })
        .catch(() => {
          window.location = '/error'
        })
      }
    }
  }

  render() {
    const {
      classes,
      isValidated,
      oktaEmail,
      oktaFullName,
      oktaLink,
      setIsValidated } = this.props

    return (
      <section className={classes.test}>
        <h1 data-test-id='hello-h1' className={classes.bigHeader}>{`${Messages['pageMessages']['HELLO']} ${oktaFullName}`}</h1>
        <h1 className={classes.smallHeader}>{`${Messages['pageMessages']['FOLLOW_STEPS']}`}</h1>
        <Paper className={classes.centerStepper}>
          <VerticalStepper isValidated={isValidated} oktaEmail={oktaEmail} oktaLink={oktaLink} setIsValidated={setIsValidated}/>
        </Paper>
        <Typography className={classes.footerTypography}>{Messages['pageMessages']['FOOTER_NOTE']}</Typography>
      </section>
    )
  }
}

Onboard.propTypes = PROP_TYPES

const mapStateToProps = state => ({
  oktaEmail: state.onboard.oktaEmail,
  oktaFullName: state.onboard.oktaFullName,
  oktaLink: state.onboard.oktaLink,
  isValidated: state.onboard.isValidated
})

const mapDispatchToProps = dispatch => bindActionCreators({
  postAudit,
  setIsValidated,
  setNoOnboardData,
  setOktaData
}, dispatch)

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Onboard)
