import React from 'react'
import ErrorBox from './errorBox'
import GenericBox from './genericBox'
import PropTypes from 'prop-types'
import SuccessBox from './successBox'

const PROP_TYPES = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

function MessageContainer(props) {
  const { message, type } = props
  switch(type) {
    case 'error':
      return <ErrorBox message={message} />
    case 'success':
      return <SuccessBox message={message} />
    default:
      return <GenericBox message={message} />
  }
}

/**
 * Props definition
 */
MessageContainer.propTypes = PROP_TYPES

export default MessageContainer
