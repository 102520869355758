import React, { Component } from 'react'
import Button from 'material-ui/Button';
import { CircularProgress } from 'material-ui/Progress'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  buttonText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  message: PropTypes.string.isRequired
}

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  smallPaddingBottom: {
    paddingBottom: '1em'
  },
  pageMessage: theme.pageMessage,
  progress: theme.progress,
  noBottomMargin: {
    marginBottom: 0
  },
  message: theme.message
})

export class PasswordWarning extends Component {

  render() {
    const { buttonText, classes, handleClick, message } = this.props

    return (
      <div className={`${classes.pageMessage} ${classes.noBottomMargin}`} data-test-id="session-check">
        <CircularProgress className={classes.progress} size={50} />
        <span className={`${classes.message} ${classes.smallPaddingBottom}`}>{message}</span>
        <div className={classes.buttonContainer}>
          <Button onClick={handleClick}>{buttonText}</Button >
        </div>
      </div>
    )
  }
}

/**
 * Props definition
 */
PasswordWarning.propTypes = PROP_TYPES

export default withStyles(styles)(PasswordWarning)
