import React, { Component } from 'react'
import Button from 'material-ui/Button'
import { CircularProgress } from 'material-ui/Progress'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  disableForm: PropTypes.bool.isRequired,
  signInButtonLabel: PropTypes.string.isRequired,
  signInInProgress: PropTypes.bool.isRequired
}

const styles = theme => ({
  signInButton: theme.signInButton,
  signInButtonContainer: theme.signInButtonContainer,
  signInButtonDisabled: theme.signInButtonDisabled,
  signInButtonLabel: theme.signInButtonLabel,
  signInButtonProgress: theme.signInButtonProgress
})

let cx = classNames.bind(styles)

export class SignInButton extends Component {

  render() {
    const {
      classes,
      disableForm,
      signInButtonLabel,
      signInInProgress
    } = this.props

    return (
      <div className={classes.signInButtonContainer}>
        <Button
          className={cx({'inProgress': signInInProgress})}
          classes={{
            disabled: classes.signInButtonDisabled,
            label: classes.signInButtonLabel,
            root: classes.signInButton
          }}
          data-test-id="sign-in-button"
          disabled={disableForm}
          type="submit">
          {signInButtonLabel}
        </Button>
        {signInInProgress &&
          <CircularProgress
            data-test-id="sign-in-progress"
            size={24} className={classes.signInButtonProgress} />}
      </div>
    )
  }
}

/**
 * Props definition
 */
SignInButton.propTypes = PROP_TYPES

export default withStyles(styles)(SignInButton)
