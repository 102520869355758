const styles = theme => ({
  '@global': {
    html: {
      background: theme.palette.background.default,
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    },
    body: {
      fontFamily: 'BrandonText',
      margin: 0
    },
    h1: {
      fontSize: 16,
      letterSpacing: 0.3,
      color: '#393939',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 'normal'
    }
  }
})

export default styles
