import Messages from '../constants/messages'

export const required = value => {
  let results = value ? undefined : Messages['fieldErrors']['REQUIRED']
  return (results)
}

export const email = value => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/.test(value) ? undefined :
    Messages['fieldErrors']['EMAIL']
}

export const notNordstromEmail = value => {
  return (value.toUpperCase().includes('@NORDSTROM.COM') || value.toUpperCase().includes('@NORDSTROM.NET') ?
    Messages['fieldErrors']['NORD_EMAIL'] : undefined)
}
