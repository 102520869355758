import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import EmpIcon3x from './images/emp-icon@3x.png'
import MessageContainer from '../../components/store-checkout-skin/messageContainer'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import withStyles from 'material-ui/styles/withStyles'

const PROP_TYPES = {
  classes: PropTypes.object.isRequired,
  containerStyle: PropTypes.object,
  message: PropTypes.string,
  showNotification: PropTypes.bool
}

const styles = theme => ({
  root: theme.root,
  constainer: {
    paddingTop: 40
  },
  [theme.breakpoints.up('lg')]: {
    root: {
      width: 493,
      margin: '25px auto',
      padding: 20
    },
    mobileOnly: {
      display: 'none'
    }
  },
  [theme.breakpoints.between('xs', 'md')]: {
    desktopOnly: {
      display: 'none'
    }
  },
  nordstromHeader: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    position: 'relative'
  },
  empIcon: {
    width: 92,
    height: 92
  }
})

export class CoreLayout extends Component {

  render() {
    const {
      applicationTitle,
      classes,
      component,
      containerStyle,
      message,
      messageType,
      showNotification,
      ...rest } = this.props
    const Component = this.props.component

    return (
      <Route {...rest} render={matchProps => (
        <section className={classes.constainer} style={containerStyle}>
          <div className={classes.mobileOnly}>
            {showNotification ? <MessageContainer message={message} type={messageType} /> : null}
          </div>
          <section className={classes.root}>
            <header className={classes.nordstromHeader}>
             <img data-test-id="emp-icon"
                  src={EmpIcon3x}
                  className={classes.empIcon}
                  alt="employee icon" />
            </header>
            <div className={classes.desktopOnly}>
              {showNotification ? <MessageContainer message={message} type={messageType} /> : null}
            </div>
            {applicationTitle ? <h1 data-test-id="application-title">{applicationTitle}</h1> : null}
            <Component {...matchProps} />
          </section>
        </section>
      )} />
    )
  }
}

CoreLayout.propTypes = PROP_TYPES

const mapStateToProps = state => ({
  containerStyle: state.clientConfigurations.containerStyle,
  message: state.notifications.message,
  messageType: state.notifications.messageType,
  showNotification: state.notifications.showNotification
})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(CoreLayout)
