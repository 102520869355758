import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { required, email, notNordstromEmail } from '../utils/validationRules'
import InputField from '../components/common/inputField';
import Messages from '../constants/messages'
import { sendForgotPasswordEmail } from '../modules/forgotPassword'
import { reduxForm } from 'redux-form'
import { Redirect } from 'react-router-dom'
import SignInButton from '../components/common/signInButton';
import withStyles from 'material-ui/styles/withStyles'

const styles = theme => ({
  introText: theme.introText,
  signInLink: {
    border: 'none',
    background: 'none',
    color: 'black',
    'font-family': 'BrandonText',
    fontSize: 15,
    display: 'inline-block',
    margin: '15px 10px 5px',
    padding: 0,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

class ForgotPassword extends Component {

  render() {

    const {
      authConfigs,
      classes,
      handleSubmit,
      isSubmitting,
      sendForgotPasswordEmail } = this.props

    if (authConfigs.env === undefined) {
      // Must hit login endpoint first
      return <Redirect to="/403" />
    }

    return (
      <section>
        <form className="login-form" onSubmit={handleSubmit((formValues) => {
          sendForgotPasswordEmail(formValues.email, authConfigs.clientId)
        })}>
          <InputField
            autoFocus={true}
            disableForm={isSubmitting}
            label={Messages['pageMessages']['EMAIL_FIELD_LABEL']}
            name="email"
            type="text"
            validate={[required, email, notNordstromEmail]} />
          <SignInButton
            disableForm={isSubmitting}
            signInButtonLabel={Messages['pageMessages']['FORGOT_PASSWORD_EMAIL_RESET_BUTTON']}
            signInInProgress={isSubmitting} />
          <button
            data-test-id="sign-in-link"
            onClick={() => window.history.back()}
            className={classes.signInLink}>
            {Messages['pageMessages']['SIGN_IN_LINK']}
          </button>
        </form>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  authConfigs: state.login.authConfigs,
  isSubmitting: state.forgotPassword.isSubmitting
})

const mapDispatchToProps = dispatch => bindActionCreators({
  sendForgotPasswordEmail
}, dispatch)

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'forgot-password'
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(ForgotPassword)